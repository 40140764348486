import { createApp } from 'vue';
import ComponentImport from './configure/vant.js';
import App from './App.vue';
import router from './router';
import store from './store';
const app = createApp(App);
ComponentImport(app);
app.use(store).use(router).mount('#app')


