import AMap from "AMap";
import { GetAmapIpLocation } from "@/serviceApi/Order";
import { setCookie, getCookie } from "@/commonContent/js/Cookie";
import { GetIp } from "@/serviceApi/Resource";
import { AddDriverCoordinates } from "@/serviceApi/Resource";
import { GetTokeUserInfo } from "@/utils/validate .js";
import { GetWeChatLocation } from "@/utils/wxPositioning";
export const noBugInCode = () => {
    let type = Math.floor(Math.random() * 10 + 1);
    if (type % 2 === 1) {
      console.info(
        "" +
          "      ┏┓　　　┏┓+ +\n" +
          "    ┏┛┻━━━┛┻┓ + +\n" +
          "    ┃　　　　　　　┃ 　\n" +
          "    ┃　　　━　　　┃ ++ + + +\n" +
          "    ████━█████    ┃ +\n" +
          "    ┃　　　　　　　┃ +\n" +
          "    ┃　　　┻　　　┃\n" +
          "    ┃　　　　　　　┃ + +\n" +
          "    ┗━┓　　　┏━┛\n" +
          "        ┃　　　┃\n" +
          "        ┃　　　┃ + + + +\n" +
          "        ┃　　　┃　　　　Code is far away from bug with the animal protecting\n" +
          "        ┃　　　┃ + 　　　　神兽保佑,代码无bug\n" +
          "        ┃　　　┃\n" +
          "        ┃　　　┃ + \n" +
          "        ┃ 　　 ┗━━━┓ + +\n" +
          "        ┃ 　　　　　　　┣┓\n" +
          "        ┃ 　　　　　　　┏┛\n" +
          "        ┗┓┓┏━┳┓┏┛ + + + +\n" +
          "          ┃┫┫　┃┫┫\n" +
          "          ┗┻┛　┗┻┛+ + + +\n" +
          "\n" +
          "   bug是不可能有bug的,这辈子都不可能有bug的\n" +
          "   有bug? 那一定是你电脑有问题\n" +
          "\n"
      );
    } else {
      console.info(
        "" +
          "                       _oo0oo_ \n" +
          "                      o888888888o \n" +
          '                      88" . "88 \n' +
          "                      (| -_- |) \n" +
          "                      0\\  =  /0 \n" +
          "                    ___/`---'\\___ \n" +
          "                  .' \\\\|     |\" '. \n" +
          '                 / \\\\|||  :  |||" \\ \n' +
          "                / _||||| -:- |||||- \\ \n" +
          '               |   | \\\\\\  -  "/ |   | \n' +
          "               | \\_|  ''\\---/''  |_/ | \n" +
          "               \\  .-\\__  '-'  ___/-. / \n" +
          "             ___'. .'  /--.--\\  `. .'___ \n" +
          '          ."" \'<  `.___\\_<|>_/___.\' >\' "". \n' +
          "         | | :  `- \\`.;`\\ _ /`;.`/ - ` : | | \n" +
          "         \\  \\ `_.   \\_ __\\ /__ _/   .-` /  / \n" +
          "     =====`-.____`.___ \\_____/___.-`___.-'===== \n" +
          "                       `=---=' \n" +
          "     ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ \n" +
          "             佛祖保佑             永无BUG  \n" +
          "     佛曰:   \n" +
          "             小农村里小民房，小民房里小民工；   \n" +
          "             小民工人写程序，又拿代码讨赏钱。   \n" +
          "             钱空只在代码中，钱醉仍在代码间；   \n" +
          "             有钱无钱日复日，码上码下年复年。   \n" +
          "             但愿老死代码间，不愿鞠躬奥迪前，   \n" +
          "             奥迪奔驰贵者趣，程序代码贫者缘。  \n" +
          "             若将贫贱比贫者，一在平地一在天；   \n" +
          "             若将贫贱比车马，他得驱驰我得闲。 \n" +
          "     ............................................. \n"
      );
    }
  };

  export const locationPositioning=()=>{
    if(process.env.VUE_APP_SkyMap=="true"&& navigator.userAgent.toLowerCase().indexOf("micromessenger") >= 0){
      let tokenStr = getCookie("token");
     if(tokenStr){
      GetWeChatLocation().then((res)=>{
        if(res=="ok"){
          let address = JSON.parse(decodeURIComponent(getCookie("location")));
          if (GetTokeUserInfo().did != "" && address) {
            AddDriverCoordinates({ params: ""}).then((res) => {}); 
         }
        }
      })
     }
    }else{
      AMap.plugin("AMap.Geolocation", function() {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          buttonOffset: new AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,
          //  定位按钮的排放位置,  RB表示右下
          buttonPosition: "RB",
        });
        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete);
        AMap.event.addListener(geolocation, "error", onError);
        function onComplete(data) {
          sessionStorage.removeItem("iPLocation");
          var coordinate = data.position.lng + "," + data.position.lat;
          let getg=data.addressComponent.province+data.addressComponent.city+data.addressComponent.district;
          var parse = {
            coordinate: coordinate,
          };
          if (parse.coordinate == "") {
            return;
          }
          if (data.status == 1) {
            let positionLocation  = JSON.parse(
              decodeURIComponent(getCookie("location"))
            );
            if (!positionLocation ) {
              positionLocation  = {
                lng: "",
                lat: "",
                Province:"",
                City:"",
                District:"",
                Township :"",
                DetailAddress:"",
              }
            }
            let valLocation=false;
            if( positionLocation.lng==data.position.lng && positionLocation .lat == data.position.lat){
              valLocation=true;
            }
            positionLocation.lng = data.position.lng;
            positionLocation.lat = data.position.lat;
            positionLocation.Province=data.addressComponent.province;
            positionLocation.City=data.addressComponent.city;
            positionLocation.District=data.addressComponent.district;
            positionLocation.Township=data.addressComponent.township;
            positionLocation.DetailAddress=data.formattedAddress.replace(getg,"");
            setCookie("location", JSON.stringify(positionLocation ));
            if (GetTokeUserInfo().did != "" && positionLocation.lng != "" && !valLocation) {
               AddDriverCoordinates({ params: ""}).then((res) => {}); 
            }
          }
        }
        function onError(data) {
          let iPLocation=false;
          let ipDate=JSON.parse(sessionStorage.getItem("iPLocation"));
          if(ipDate){
            iPLocation=ipDate;
          }
          GetIp().then((res) => {
            let ip = "";
            if (res.data.ip.indexOf(":") != -1) {
              let ipset = res.data.ip.split(":");
              ip = ipset[0];
            } else {
              ip = res.data.ip;
            };
            
           if(!iPLocation){
            GetAmapIpLocation(ip).then((res) => {
              if (res.status == 1 && res.rectangle.length != 0) {
                iPLocation=true;
                sessionStorage.setItem("iPLocation",JSON.stringify(iPLocation));
                let conset=res.rectangle.split(';')
                let lantlat=conset[0]
                let lant=lantlat.split(',');
                let lngTwo= conset[1].split(',');
                let centerPointLng=(parseFloat(lant[0])+parseFloat(lngTwo[0]))/2;
                let centerPointLat=(parseFloat(lant[1])+parseFloat(lngTwo[1]))/2;
                let positionLocation  = JSON.parse(
                  decodeURIComponent(getCookie("location"))
                );
                if (!positionLocation ) {
                  positionLocation  = {
                    DetailAddress:res.city,
                    lng: "",
                    lat: "",
                    City:res.city,
                    District:"",
                    Township:"",
                    Province:res.province
                  };
                }
                let valLocation=false;
                if( positionLocation.lng==centerPointLng && positionLocation.lat == centerPointLat){
                 valLocation=true;
                }
                positionLocation.DetailAddress=res.city,
                positionLocation.lng = centerPointLng;
                positionLocation.lat = centerPointLat;
                positionLocation.City=res.city,
                positionLocation.District="",
                positionLocation.Township="",
                positionLocation.Province=res.province
                setCookie("location",JSON.stringify(positionLocation ));
                if (GetTokeUserInfo().did != "" && positionLocation.lng != "" && !valLocation) {
                  AddDriverCoordinates({ params: "" }).then((res) => {});
                }
              } else {
                sessionStorage.removeItem("iPLocation");
              }
            });
           }
          });
        }
      });
    }
  }