import {dynamic} from './dynamicRouting';
import {ViewApiVersion} from '../serviceApi/View';
import { delUrlParam} from '@/commonContent/js/comm';
import{RouterStatus}from '@/Enum/enumeration';
import { Toast } from "vant";
/* 静态路由 */
 const route=[
   {
     path: '/',
     name: 'Home',
     redirect: '/Home/UserOrder',
     meta: {
       title: '首页',
       keepAlive: true,
       IsLogin:true,
         
     },
     children: [
       {
         path: '/Home/DriverOrder',
         name: 'DriverOrder',
         component:() => import(`../views/Home/DriverOrder.vue`),
         meta: {
         title: '司机接单列表',
         keepAlive: true,
         IsLogin:true,
         },
       },
       {
         path: '/Home/UserOrder',
         name: 'UserOrder',
         component:() => import(`../views/Home/UserOrder.vue`),
         meta: {
           title: '用户发单',
          keepAlive: true,
          IsLogin:true,
         },
       },
      
     ]
   },
   {
     path: '/Login',
     name: 'Login',
     meta: {
       title: '登录',
       IsLogin:true,
      
     },
   },
   {
     path: '/Order',
     name: 'Order',
     redirect: '/Order/UserOrderList',
     meta: {
       title: '我的订单',
      keepAlive: true,
      IsLogin:true,
     },
     children:[
       {
         path: '/Order/UserOrderList',
         name:'UserOrderList',
       /*   component:() => import("../views/Order/UserOrderList.vue"), */
         meta: {
          title: '用户订单',
          keepAlive: true,
          IsLogin:true,
         },
       },
       {
         path: '/Order/DriverOrderList',
         name:'DriverOrderList',
         /* component:() => import("../views/Order/DriverOrderList.vue"), */
          meta: {
           title: '司机订单',
           keepAlive: true,
           IsLogin:true,
          },
       }
     ]
   },
   {
     path: '/Driver/Apply',
     name: 'Apply',
     meta: {
       title: '司机申请',
      keepAlive: true,
      IsLogin:true,
     },
   },
   {
     path: '/User/Index',
     name: 'Index',
     meta: {
       title: '个人中心',
      keepAlive: true,
      IsLogin:true,
     },
   },
   {
     path: '/Order/Confirm',
     name: 'Confirm',
     meta: {
       title: '用户发单',
       keepAlive: true,
       IsLogin:true,
     },
 
   },
   {
     path: '/Order/DriverOrderDetail',
     name: 'DriverOrderDetail',
     meta: {
       title: '司机接单详情',
       keepAlive: true,
       IsLogin:true,
     },
   },
   {
     path: '/Order/OrderDetails',
     name: 'OrderDetails',
     meta: {
       title: '订单详情',
      keepAlive: true,
      IsLogin:true,
     },
   },
   {
     path: '/Order/OrderPublicMap',
     name: 'OrderPublicMap',
     meta: {
       title: '物流详情',
      keepAlive: true,
      IsLogin:false,
     },
   },
 {
     path: '/Driver/Info',
     name: 'DriverInfo',
     meta: {
       title: '司机信息',
      keepAlive: true,
      IsLogin:true,
     },
   }
   , {
     path: '/User/EditPassword',
     name: 'EditPassword',
     meta: {
       title: '登录密码修改',
      keepAlive: true,
      IsLogin:true,
     },
   },
   {
     path:'/Article/ArticleType',
     name:"ArticleType",
     meta:{
       title:'系统帮助',
       keepAlive:true,
       IsLogin:true,
     },
   },
   {
     path:'/Article/ArticleInfo',
     name:"ArticleInfo",
     meta:{
       title:'内容分类',
       keepAlive:true,
       IsLogin:true,
     },
   },
   {
     path:'/Article/Article',
     name:"Article",
     meta:{
       title:'内容详情',
       keepAlive:true,
       IsLogin:true,
     },
   }, 
   {
     path:'/Article/ArticleView',
     name:"ArticleView",
    
     meta:{
       title:'内容分类',
       keepAlive:true,
       IsLogin:true,
     },
   }, 
   {
     path: '/User/Info',
     name: 'Info',
     meta: {
     title: '用户信息',
     keepAlive: true,
     IsLogin:true,
     },
   },
   {
     path: '/Order/Evaluation',
     name: 'Evaluation',
     meta: {
       title: '订单评价',
      keepAlive: true,
      IsLogin:true,
     },
   }, {
     path: '/Order/Cancel',
     name: 'Cancel',
     meta: {
       title: '取消订单',
      keepAlive: true,
      IsLogin:true,
     },
   },
   {
     path: '/User/UpdateNickname',
     name: 'UpdateNickname',
     meta: {
       title: '昵称修改',
      keepAlive: true,
      IsLogin:true,
     },
   },
   {
     path:'/Pay/Index',
     name:'PayIndex',
     meta:{
     title: '付款',
     keepAlive: true,
     IsLogin:true,
     }
   },
   {
     path:'/Pay/PayResult',
     name:'PayResult',
     meta:{
       title: '支付结果',
      keepAlive: true,
      IsLogin:true,
     }    
   }, {
     path:'/Fund/BalanceRecord',
     name:'BalanceRecord',
     meta:{
       title: '资金管理',
      keepAlive: true,
      IsLogin:true,
     }  
   }, {
     path:'/Fund/WithdrawRecord',
     name:'WithdrawRecord',
     meta:{
       title: '提现记录',
     keepAlive: true,
     IsLogin:true,
     }  
   }, {
     path:'/Fund/Account',
     name:'Account',
     meta:{
       title: '账户管理',
     keepAlive: true,
     IsLogin:true,
     }  
   }, {
     path:'/Fund/AccountEdit',
     name:'AccountEdit',
     meta:{
       title: '账户操作',
     keepAlive: true,
     IsLogin:true,
     }  
   },
   {
     path: '/Pay/Recharge',
     name: 'Recharge',
     meta:{
       title: '充值',
       keepAlive: true,
       IsLogin:true,
     }  
   },
   {
     path:'/Fund/Withdraw',
     name:'Withdraw',
     meta:{
       title:'提现确定',
       keepAlive:true,
       IsLogin:true,
     }
   },
   {
     path:'/User/EditAccount',
     name:"EditAccount",
     meta:{
       title:'手机管理',
       keepAlive:true,
       IsLogin:true,
     },
   },{
     path:'/Driver/UpdateDriver',
     name:"UpdateDriver",
     meta:{
       title:'司机信息修改',
       keepAlive:true,
       IsLogin:true,
     },
   },{
     path:'/Sales/RedPack',
     name:"RedPack",
     meta:{
       title:'红包信息',
       keepAlive:true,
       IsLogin:true,
     },
   },{
     path:'/Sales/Coupon',
     name:"Coupon",
     meta:{
       title:'优惠券信息',
       keepAlive:true,
       IsLogin:true,
     },
   },{
     path:'/Sales/GetDeduction',
     name:"GetDeduction",
     meta:{
       title:'领取优惠',
       keepAlive:true,
       IsLogin:true,
     },
   },{
     path:'/Error/404',
     name:"404",
     meta:{
       title:'404',
       keepAlive:true,
       IsLogin:true,
     },
   },
   {
     path:'/Order/MapDownload',
     name:'MapDownload',
     meta:{
       title:'地图下载',
       keepAlive:true,
       IsLogin:true,
     }
   },  {
     path:'/User/ResetPayPassword',
     name:'ResetPayPassword',
     meta:{
       title:'支付密码重置',
       keepAlive:true,
       IsLogin:true,
     }
   },  {
     path:'/User/EditPayPassword',
     name:'EditPayPassword',
     meta:{
       title:'支付密码修改',
       keepAlive:true,
       IsLogin:true,
     }
   },
   {
     path:'/Chat/UserList',
     name:'ChatUserList',
     meta:{
       title:'消息',
       keepAlive:true,
       IsLogin:true,
     }
   },{
     path:'/Chat/ChatUserMsg',
     name:'ChatUserMsg',
     meta:{
       title:'聊天',
       keepAlive:true,
       IsLogin:true,
     }
   },{
     path:'/Chat/Notice',
     name:'Notice',
     meta:{
       title:'通知',
       keepAlive:true,
       IsLogin:true,
     }
   },{
       path:'/Feedback/Feedback',
       name:'Feedback',
       meta:{
         title:'建议反馈',
         keepAlive:true,
         IsLogin:true,
       }
   }
 ]; 
 const router=[
  {
    path: '/',
    name: 'Login',
    meta: {
      title: '登录',
      IsLogin:true,
      keepAlive: true,
     
    },
  },{
    path: '/Order/OrderPublicMap',
    name: 'OrderPublicMap',
    meta: {
      title: '物流详情',
      IsLogin:false,
      keepAlive: true,
    },
  },{
    path: '/Driver/QuickRegister',
    name: 'QuickRegister',
    meta: {
      title: '认证信息',
      IsLogin:false,
      keepAlive: true,
    },
  },{
    path: '/Driver/CancelDriver',
    name: 'CancelDriver',
    meta: {
      title: '司机解绑申请',
      IsLogin:false,
      keepAlive: true,
    },
  },{
    path:'/Error/404',
    name:"404",
    meta:{
      title:'404',
      keepAlive:true,
      IsLogin:true,
    },
  }
 ];
export const routes=dynamic(router);
export const viewList= ()=>{
    let ArrRoutes=[];
    let ArrRoutesL=[];
    let ApiVersion="M_V1"
    ViewApiVersion(ApiVersion).then((res)=>{
      if(res.code==1){
      let viewList=res.data;
      let vieList= HandleTree(res.data,0);
      if(vieList[0].isActive==RouterStatus.Normal){
       for (let index = 0; index < vieList[0].child.length; index++) {
        if(vieList[0].child[index].isActive==RouterStatus.Normal){
          for (let j = 0; j < vieList[0].child[index].child.length; j++) {
           if(vieList[0].child[index].child[j].isActive==RouterStatus.Normal){
                 let routes={
                     path: '',
                     name: '',
                     meta: {
                        title: '',
                        keepAlive: true,
                         }
                       };
             if(vieList[0].child[index].child[j].path=='/Home' || vieList[0].child[index].child[j].path=='/Order'){
                 routes.path=vieList[0].child[index].child[j].path;
                 routes.name=vieList[0].child[index].child[j].name;
                 routes.meta.title=vieList[0].child[index].child[j].label;
                 routes.meta.IsLogin=true;
               if(vieList[0].child[index].child[j].path=='/Home'){
               routes.children=[];
               for (let b = 0; b < vieList[0].child[index].child.length; b++) {
                 let childrenRoutes={
                   path: '',
                   name: '',
                   meta: {
                     title: '',
                     keepAlive: true,
                     IsLogin:true,
                   }
                 };
                  if((vieList[0].child[index].child[b].path=="/Home/DriverOrder" || vieList[0].child[index].child[b].path=="/Home/UserOrder" )&& vieList[0].child[index].child[b].isActive==RouterStatus.Normal){
                   childrenRoutes.path=vieList[0].child[index].child[b].path;
                   childrenRoutes.name=vieList[0].child[index].child[b].name;
                   childrenRoutes.meta.title=vieList[0].child[index].child[b].label;
                   routes.children.push(childrenRoutes);
                  }
               }
               routes.redirect='/Home/UserOrder'
             }else{
               routes.children=[];
               for (let b = 0; b < vieList[0].child[index].child.length; b++) {
                 let childrenRoutes={
                   path: '',
                   name: '',
                   meta: {
                     title: '',
                     keepAlive: true,
                     IsLogin:true,
                   }
                 };
                  if((vieList[0].child[index].child[b].path=="/Order/DriverOrderList" || vieList[0].child[index].child[b].path=="/Order/UserOrderList" )&& vieList[0].child[index].child[b].isActive==RouterStatus.Normal){
                  childrenRoutes.path=vieList[0].child[index].child[b].path;
                   childrenRoutes.name=vieList[0].child[index].child[b].name;
                   childrenRoutes.meta.title=vieList[0].child[index].child[b].label;
                   routes.children.push(childrenRoutes);
                  }
               }
               routes.redirect='/Order/UserOrderList'
             }
              ArrRoutesL.push(routes);
            }
           if(vieList[0].child[index].child[j].path!='/Home/DriverOrder'&&vieList[0].child[index].child[j].path!='/Home/UserOrder'&&vieList[0].child[index].child[j].path!='/Order/DriverOrderList' && vieList[0].child[index].child[j].path!='/Order/UserOrderList' && vieList[0].child[index].child[j].path!='/' && vieList[0].child[index].child[j].path!='/Order' && vieList[0].child[index].child[j].path!='/Home'){
             routes.path=vieList[0].child[index].child[j].path;
             routes.name=vieList[0].child[index].child[j].name;
             routes.meta.title=vieList[0].child[index].child[j].label;
             routes.meta.IsLogin=true;
             if(vieList[0].child[index].child[j].path=='/Order/OrderPublicMap'||vieList[0].child[index].child[j].path=='/Driver/QuickRegister'){
               routes.meta.IsLogin=false;
             }
             ArrRoutesL.push(routes);
           }
           }
          }
        }
       }
      sessionStorage.setItem("view",JSON.stringify(ArrRoutesL));
     }
   /*    for (let index = 0; index < viewList.length; index++) {
        if(viewList[index].name!="Mobile" && viewList[index].isActive==1){
          let routes={
            path: '',
            name: '',
            meta: {
              title: '',
              keepAlive: true,
            }
          };
           if(viewList[index].path=='/Home' || viewList[index].path=='/Order'){
              routes.path=viewList[index].path;
              routes.name=viewList[index].name;
              routes.meta.title=viewList[index].label;
              routes.meta.IsLogin=true;
              if(viewList[index].path=='/Home'){
                routes.children=[];
                for (let j = 0; j < viewList.length; j++) {
                  let childrenRoutes={
                    path: '',
                    name: '',
                    meta: {
                      title: '',
                      keepAlive: true,
                      IsLogin:true,
                    }
                  };
                   if((viewList[j].path=="/Home/DriverOrder" || viewList[j].path=="/Home/UserOrder" )&& viewList[j].isActive==1){
                    childrenRoutes.path=viewList[j].path;
                    childrenRoutes.name=viewList[j].name;
                    childrenRoutes.meta.title=viewList[j].label;
                    routes.children.push(childrenRoutes);
                   }
                }
                routes.redirect='/Home/UserOrder'
              }else{
                routes.children=[];
                for (let j = 0; j < viewList.length; j++) {
                  let childrenRoutes={
                    path: '',
                    name: '',
                    meta: {
                      title: '',
                      keepAlive: true,
                      IsLogin:true,
                    }
                  };
                   if((viewList[j].path=="/Order/DriverOrderList" || viewList[j].path=="/Order/UserOrderList")&& viewList[j].isActive==1 ){
                    childrenRoutes.path=viewList[j].path;
                    childrenRoutes.name=viewList[j].name;
                    childrenRoutes.meta.title=viewList[j].label;
                    routes.children.push(childrenRoutes);
                   }
                }
                routes.redirect='/Order/UserOrderList'
              }
              ArrRoutes.push(routes);
          }
          if( viewList[index].path!='/Home/DriverOrder'&&viewList[index].path!='/Home/UserOrder'&&viewList[index].path!='/Order/DriverOrderList' && viewList[index].path!='/Order/UserOrderList' && viewList[index].path!='/' && viewList[index].path!='/Order' && viewList[index].path!='/Home'){
              routes.path=viewList[index].path;
              routes.name=viewList[index].name;
              routes.meta.title=viewList[index].label;
              routes.meta.IsLogin=true;
              ArrRoutes.push(routes);
          }
        } 
      }  */
     /*  sessionStorage.setItem("view",JSON.stringify(ArrRoutes)) */
      history.replaceState({}, '', delUrlParam(window.location.href, ["logintoken"]));
      history.replaceState({}, '', delUrlParam(window.location.href, ["tenantId"]));
      location.reload(); 
      }else{
        Toast.fail(res.msg)
      }
    })
}
/* 递归数据处理 */
export const HandleTree=(dataList, pId)=>
{
    var tree = [];
    var list = dataList.filter((t) =>{ return t.parentId == pId; });
    for (var i = 0; i < list.length; i++) {
        var data = list[i];
        var item = {isActive:data.isActive,name:data.name,path:data.path,label:data.label};
        var child = HandleTree(dataList, data.viewId);
        if (child.length > 0)
        {
            item.child = child;
        }
        tree.push(item);
    }
    return tree;
}
export const routeParameter=(parameter)=>{
 var url= delUrlParam(parameter, ["logintoken"])
    if(!url){
      return;
    }
  if(sessionStorage.getItem("parameter")){
     sessionStorage.removeItem("parameter");
  }
  sessionStorage.setItem("parameter",url);
};
/* export const routes=dynamic(router); */

