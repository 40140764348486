import { delUrlParam, getUrlParam, IsNullOrEmpty, delCookie,editUrlQuery } from '@/commonContent/js/comm'
import { Login} from "@/serviceApi/Account";
import { setCookie,getCookie } from '@/commonContent/js/Cookie';
import {viewList} from './routes'
import { Toast } from "vant";
export async function UaLogin ( callback) {
    Login({ "userName": "null", "password": "null", "verifyCode": "null", "verifyCodeKey": "null", "loginToken": getUrlParam("logintoken"),"tenantId":getUrlParam("tenantId")  }).then( res => {
     if ( res.code == 1) {
       callback(true)
       setCookie("token", res.data.token)
       viewList();
       /* sessionStorage.setItem("view",JSON.stringify(ArrRoutes))
       history.replaceState({}, '', delUrlParam(window.location.href, ["logintoken"])); */
     } else {
       setCookie("token", "")
       delCookie("ASP.NET_SessionId");
       delCookie("SessionId");
      let tryCount = getUrlParam("tryCount")
     if (!IsNullOrEmpty(tryCount)) {
       parseInt(tryCount =tryCount +1);
     }
     else {
       tryCount = 1;
     }
     let url = editUrlQuery(window.location.href, "tryCount", tryCount)
     if (tryCount > 3) {
     return Toast.fail("登陆失败");
     }
     else {
       window.location.href =""+process.env.VUE_APP_API_UA+"/account/login?returnurl="+ encodeURIComponent(url);    
     }
     }
   }).catch(err => {
   });
 }

 export const intercept=()=>{
  let tokenStr = getCookie("token");
  if (!tokenStr) {
    window.location.href = "" + process.env.VUE_APP_API_UA + "/account/login?returnurl=" + encodeURIComponent(window.location.href);
  }
 };
 