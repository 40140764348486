import axios, { changePending } from "../utils/axios";
import { Toast } from "vant";
import { setCookie, getCookie } from "@/commonContent/js/Cookie";
import { RefreshToken } from "@/serviceApi/Account";
import { IsNullOrEmpty, delUrlParam } from "@/commonContent/js/comm";
import { getUrlParam, editUrlQuery } from "@/commonContent/js/comm";
export const GetToken = (config) => {
  if (axios.intercept) {
  } else {
    changePending(config);
  }
  const token = getCookie("token");
  if (token && axios.useToken) {
    config.headers.Authorization = "Bearer " + token;
    config.url = config.url.replace(config.url.split("/")[1],config.url.split("/")[1].toLowerCase());
    return config;
  }
  if (!axios.useToken) {
    return config;
  }
  return "";
};

/**
 * @description post 请求带token
 * @author 优
 * @param {json} params 请求参数
 * @returns {Promise} Promise对象
 */
export const PostApi = (params) => {
  if (IsNullOrEmpty(params.url)) {
    return Toast.fail("请求地址不能为空");
  }
  axios.useToken = true;
  axios.intercept = params.intercept;
  return axios
    .AxiosRequest("post", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};
/**
 * @description get 请求带token
 * @author 优
 * @param {json} params 请求参数
 * @returns {Promise} Promise对象
 */
export const GetApi = (params) => {
  if (IsNullOrEmpty(params.url)) {
    return Toast.fail("请求地址不能为空");
  }
  axios.intercept = params.intercept;
  axios.useToken = true;
  params.data = params.data ? { params: params.data } : {};
  return axios
    .AxiosRequest("get", params)
    .then((res) => { 
      return res;
    })
    .catch((err) => {});
};
/**
 * @description put 请求带token
 * @author 优
 * @param {json} params 请求参数
 * @returns {Promise} Promise对象
 */
export const PutApi = (params) => {
  if (IsNullOrEmpty(params.url)) {
    return Toast.fail("请求地址不能为空");
  }
  axios.intercept = params.intercept;
  axios.useToken = true;
  return axios
    .AxiosRequest("put", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};
/**
 * @description delete 请求带token
 * @author 优
 * @param {json} params 请求参数
 * @returns {Promise} Promise对象
 */
export const DeleteApi = (params) => {
  if (IsNullOrEmpty(params.url)) {
    return Toast.fail("请求地址不能为空");
  }

  axios.intercept = params.intercept;
  axios.useToken = true;
  params.data={data:params.data};
  return axios
    .AxiosRequest("delete", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};
/**
 * @description post 请求不带token
 * @author 优
 * @param {json} params 请求参数
 * @returns {Promise} Promise对象
 */
export const NoTokenPostApi = (params) => {
  if (IsNullOrEmpty(params.url)) {
    return Toast.fail("请求地址不能为空");
  }
  axios.useToken = false;
  return axios
    .AxiosRequest("post", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};
/**
 * @description get 请求不带token
 * @author 优
 * @param {json} params 请求参数
 * @returns {Promise} Promise对象
 */
export const NoTokenGetApi = (params) => {
  if (IsNullOrEmpty(params.url)) {
    return Toast.fail("请求地址不能为空");
  }
  axios.useToken = false;
  params.data = params.data ? { params: params.data } : {};
  return axios
    .AxiosRequest("get", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};
/**
 * @description put 请求不带token
 * @author 优
 * @param {json} params 请求参数
 * @returns {Promise} Promise对象
 */
export const NoTokenPutApi = (params) => {
  if (IsNullOrEmpty(params.url)) {
    return Toast.fail("请求地址不能为空");
  }
  axios.useToken = false;
  return axios
    .AxiosRequest("put", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};
/**
 * @description delete 请求不带token
 * @author 优
 * @param {json} params 请求参数
 * @returns {Promise} Promise对象
 */
export const NoTokenDeleteApi = (params) => {
  if (IsNullOrEmpty(params.url)) {
    return Toast.fail("请求地址不能为空");
  }
  axios.useToken = false;
  return axios
    .AxiosRequest("delete", params)
    .then((res) => {
      return res;
    })
    .catch((err) => {});
};

// 是否正在刷新
let isRefreshing = false;
// 重试队列 每一项都是一个待执行待函数
export const ErrorHandling = (response, error) => {
  switch (response.status) {
    case 401: //服务器拒绝执行，一般token或cookie过期
      if (!isRefreshing) {
        isRefreshing = true;
        // 获取旧 token
        var oldToken = getCookie("token");
        // 刷新token
        return RefreshToken(oldToken)
          .then((res) => {
            if (res.code == 1) {
              setCookie("token", res.data.token);
              // 重新请求接口 前过期的接口
              error.config.headers.Authorization ="Bearer " + getCookie("token");
              let request = axios.request(error.config);
              isRefreshing = false;
              return request;
            } else {
              requests = [];
              window.location.href ="" +process.env.VUE_APP_API_UA +"/account/login?returnurl=" +encodeURIComponent(delUrlParam(window.location.href, ["logintoken"]));
            }
          })
          .catch((err) => {
            /* Toast.fail("刷新token失败,重新登录") */
            let tryCount = getUrlParam("tryCount");
            if (!IsNullOrEmpty(tryCount)) {
              parseInt((tryCount = tryCount + 1));
            } else {
              tryCount = 1;
            }
            let url = editUrlQuery(window.location.href, "tryCount", tryCount);
            if (tryCount > 3) {
              return Toast.fail("登陆失败");
            } else {
              window.location.href ="" + process.env.VUE_APP_API_UA +"/account/login?returnurl=" +encodeURIComponent(url);
            }
          })
          .finally(() => {
            isRefreshing = false;
          });
      } else {
        // 正在刷新token ,把后来的接口缓冲起来
      return HomePage(error)
      }
      break;
    case 403: //权限不足
      Toast.fail("权限不足");
      break;
    case 404: //找不到地址
      Toast.fail("找不到地址");
      break;
    case 405: //方法不能请求
      Toast.fail("请求类型不正确");
      break;
    case 500: //请求失败
    Toast.fail("请求失败");
    break;
    default:
      //服务器连结果都没有返回
      if (!window.navigator.onLine) {
        //客户端断网了，将页面跳转到一个断网的页面，可以结合路由使用
        return Toast.fail("网络中断，请重试");
      }
      router.push({ name: "login" });
  }
};
function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
async function HomePage(error){
    await sleep(500);
  error.config.headers.Authorization ="Bearer " + getCookie("token");
  return axios.request(error.config);
}
 