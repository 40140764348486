/*
 * @Description:
 * @Author: 优
 * @Date: 2021-06-11 15:54:16
 * @LastEditors: 优
 * @LastEditTime: 2021-07-27 09:11:18
 */
import { PostApi, PutApi, GetApi, NoTokenGetApi,NoTokenPostApi} from "../utils/request";
import ApiVersion from "@/Enum/enumeration"

var CURR_VIEW_VERSION = ApiVersion.ApiVersion.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `/M/${CURR_VIEW_VERSION}`;

export const UserInfoGetCurrUserInfo = (params) => {
  //用户信息查询
  var data = {
    url: `/api${VIEW_VERSION}/User/UserInfo/GetCurrUserInfo`,
    data: params,
  };
  return GetApi(data);
};
export const GetCurrUserBalance = (params) => {
  //获取用余额
  var data = {
    url: `/api${VIEW_VERSION}/User/UserInfo/GetCurrUserBalance`,
    data: params,
  };
  return GetApi(data);
};
export const GetCurrUserFullFund = (params) => {
  //获取当前用户资金余额优惠劵
  var data = {
    url: `/api${VIEW_VERSION}/User/UserInfo/GetCurrUserFullFund`,
    data: { getCouponList: params },
  };
  return GetApi(data);
};
export const UserInfoUpdate = (params) => {
  //用户信息修改
  var data = {
    url: `/api${VIEW_VERSION}/User/UserInfo/Update`,
    data: params,
  };
  return PutApi(data);
};
export const UserInfoUpdatePortrait = (params) => {
  //修改头像
  var data = {
    url: `/api${VIEW_VERSION}/User/UserInfo/UpdatePortrait`,
    data: params,
  };
  return PutApi(data);
};
export const UpdatePassword = (params) => {
  //用户密码修改
  var data = {
    url: `/api${VIEW_VERSION}/User/UserInfo/UpdatePassword`,
    data: params,
  };
  return PutApi(data);
};
export const UpdatePayPassword=(params)=>{
   //支付密码修改
  var data={
    url: `/api${VIEW_VERSION}/User/UserInfo/UpdatePayPassword`,
    data:params
  }
  return PutApi(data);
}
export const ResetPayPassword=(params)=>{
  //重置密码修改
 var data={
   url: `/api${VIEW_VERSION}/User/UserInfo/ResetPayPassword`,
   data:params
 }
 return PutApi(data);
}
export const UpdateAccount = (params) => {
  //修改手机号邮箱号
  var data = {
    url: `/api${VIEW_VERSION}/User/UserInfo/UpdateAccount`,
    data: params,
  };
  return PutApi(data);
};

//获取手机/邮箱验证码
export const GetVerifyCode = (params) => {
  var data = {
    url: `/api${VIEW_VERSION}/User/Account/GetVerifyCode`,
    data: { account: params },
  };
  return NoTokenGetApi(data);
};

//校验用户账号验证码
export const CheckUserVerifyCode = (params) => {
  var data = {
    url: `/api${VIEW_VERSION}/User/Account/CheckUserVerifyCode`,
    data: { verifyCode: params },
  };
  return PostApi(data);
};

//获取用户手机/邮箱验证码
export const GetUserVerifyCode = (params) => {
  var data = {
    url: `/api${VIEW_VERSION}/User/Account/GetUserVerifyCode`,
    data: { action: params },
  };
  return GetApi(data);
};
//微信解绑
export const UntieWeChat = (params) => {
  var data = {
    url: `/api${VIEW_VERSION}/User/UserInfo/UntieWeChat`,
    data: params,
  };
  return PutApi(data);
};
//获取微信Oauth2路径
export const GetUrlWxOauth2=(params)=>{
  var data = {
    url: `/api${VIEW_VERSION}/User/Account/GetUrlWxOauth2`,
    data: params,
  };
  return NoTokenGetApi(data);
}
//获取用户设置接收消息状态
export const GetMessageConfig = (params) => {
  var data = {
    url: `/api${VIEW_VERSION}/User/UserConfig/GetMessageConfig`,
    data: { action: params },
  };
  return GetApi(data);
};
export const UpdateMessagesReceiving = (params) => {
  //设置用户是否接收消息
  var data = {
    url: `/api${VIEW_VERSION}/User/UserConfig/UpdateMessagesReceiving`,
    data: params,
  };
  return PutApi(data);
};