import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import { routes,routeParameter } from '../utils/routes'
import { UaLogin } from '../utils/UaLogin'
import { clearPending } from '../utils/axios'
import { getCookie } from '@/commonContent/js/Cookie.js';
import store from '../store/index';
import {GetBool} from "../commonContent/js/comm";
import {routerEnum} from '../Enum/enumeration';
import { Toast } from 'vant'
const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
      
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 200)
    })
  }
})

router.beforeEach((to, form, next) => {
  document.title = to.meta.title + " - 易运之家  "
  if(!sessionStorage.getItem("view") && !to.name){
    routeParameter(window.location.search);
     next({name:"Login",query:{Router:to.path}})
  };
  clearPending();
  if(sessionStorage.getItem("view")&& routes.length>routerEnum.NumberRouter){
  var path = ""
  routes.find((t) => {
    if ((t.path == to.path || t.redirect == to.path || to.path == "/Home/DriverOrder" ||to.path == "/Order/DriverOrderList" || to.path=="/Home/UserOrder")&&to.name) {
      path = "matching"
    }
  })
  if (path != "matching") {
    next({ name: "404" }) 
  } 
  }
  if(!to.meta.IsLogin){ //是否需要登录
    return next();
  }
  
  document.title = to.meta.title + " - 易运之家  "
  let tokenStr = getCookie("token");
  if (window.location.href.indexOf("logintoken") != -1) {
    const Tips = Toast.loading({
      message: '登录中...',
      duration: 0
    });
    UaLogin(function (result) {
      Tips.message = "登录成功"
      if (!result) {
        if(GetBool(process.env.VUE_APP_IS_APP)){
          window.location.href = "" + process.env.VUE_APP_API_UA + "/account/login?isapp=true&returnurl=" + encodeURIComponent(window.location.href);
         return
        }
       else{
         window.location.href = "" + process.env.VUE_APP_API_UA + "/account/login?returnurl=" + encodeURIComponent(window.location.href);
        return
      }
      }
      Tips.clear()
      next();
    });
    return
  }
  if (!tokenStr) {
    if(GetBool(process.env.VUE_APP_IS_APP)){
      window.location.href = "" + process.env.VUE_APP_API_UA + "/account/login?isapp=true&returnurl=" + encodeURIComponent(window.location.href);
    }
   else{
    window.location.href = "" + process.env.VUE_APP_API_UA + "/account/login?returnurl=" + encodeURIComponent(window.location.href);
   }
  }else{
    if(sessionStorage.getItem("OrderLocation")){
     store.state.positionInformation=JSON.parse(sessionStorage.getItem("OrderLocation"));
    };
    if(sessionStorage.getItem("OrderInformation")){
      store.state.addressData=JSON.parse(sessionStorage.getItem("OrderInformation"));
    };
    next();
  }
 
});  

/* { //静态 
  clearPending();
  var path = ""
  routes.find((t) => {
    if (t.path == to.path || t.redirect == to.path || to.path == "/Home/DriverOrder" ||to.path == "/Order/DriverOrderList") {
      path = "matching"
    }
  })
  if (path != "matching") {
    next({ name: "404" })
  } 
  if(!to.meta.IsLogin){ //是否需要登录
    return next();
  }
  document.title = to.meta.title + " - 易运之家  "
  let tokenStr = getCookie("token");
  if (window.location.href.indexOf("logintoken") != -1) {
    const Tips = Toast.loading({
      message: '登录中...',
      duration: 0
    });
    UaLogin(function (result) {
      Tips.message = "登录成功"
      if (!result) {
        if(GetBool(process.env.VUE_APP_IS_APP)){
          window.location.href = "" + process.env.VUE_APP_API_UA + "/account/login?isapp=true&returnurl=" + encodeURIComponent(window.location.href);
         return
        }
       else{
        window.location.href = "" + process.env.VUE_APP_API_UA + "/account/login?returnurl=" + encodeURIComponent(window.location.href);
        return
      }
      }
      Tips.clear()
      next();
    });
    return
  }
  if (!tokenStr) {
    if(GetBool(process.env.VUE_APP_IS_APP)){
      window.location.href = "" + process.env.VUE_APP_API_UA + "/account/login?isapp=true&returnurl=" + encodeURIComponent(window.location.href);
    }
   else{
    window.location.href = "" + process.env.VUE_APP_API_UA + "/account/login?returnurl=" + encodeURIComponent(window.location.href);
   }
  }else{
    
    next();
  }
} */
export default router
