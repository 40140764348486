import {Icon,Picker,Image as VanImage,Step,SwipeCell ,DatetimePicker ,Steps,Area,Checkbox,CheckboxGroup,Lazyload,Loading  ,Search ,Switch ,Cascader,AddressList,Card,AddressEdit,CollapseItem,Popover,PasswordInput,NumberKeyboard, RadioGroup,Tag,Radio ,Collapse,ActionSheet,List,Calendar,Toast,Form, Button, NavBar, Swipe, SwipeItem, Skeleton, Field,ImagePreview , Divider, DropdownMenu, DropdownItem, PullRefresh,Dialog, Cell,CellGroup, Col, Row, Rate, Tab, Tabs, Popup, Uploader, Grid, GridItem, IndexBar, IndexAnchor, ActionBar,ActionBarIcon, ActionBarButton, CouponList, CouponCell, SubmitBar,NoticeBar} from 'vant';
import axios from 'axios'
import checkPermissionMix from '../mixin/index'
import "emoji-mart-vue-fast/css/emoji-mart.css"; 
/* import 'default-passive-events'; */
export default (app)=>{
    app.use(NoticeBar)
    .use(Collapse)
    .use(Step)
    .use(DatetimePicker)
    .use(Steps)
    .use(SwipeCell)
    .use(CollapseItem)
    .use(Lazyload)
    .use(Loading)
    .use(Area)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Cascader)
    .use(Switch)
    .use(Search)
    .use(PasswordInput)
    .use(NumberKeyboard)
    .use(Tag)
    .use(Toast)
    .use(ActionSheet)
    .use(Calendar)
    .use(ImagePreview )
    .use(Popover)
    .use(RadioGroup)
    .use(Radio)
    .use(Card)
    .use(Picker)
    .use(Tab)
    .use(SubmitBar)
    .use(ActionBar)
    .use(CouponCell)
    .use(CouponList)
    .use(ActionBarIcon)
    .use(ActionBarButton)
    .use(Tabs)
    .use(Uploader)
    .use(IndexBar)
    .use(IndexAnchor)
    .use(VanImage)
    .use(Grid)
    .use(GridItem)
    .use(Popup)
    .use(Form)
    .use(Swipe)
    .use(Cell)
    .use(Rate)
    .use(Col)
    .use(Row)
    .use(Dialog)
    .use(CellGroup)
    .use(PullRefresh)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(Divider)
    .use(Field)
    .use(Skeleton)
    .use(SwipeItem)
    .use(Icon)
    .use(Button)
    .use(NavBar)
    .use(List)
    .use(AddressEdit )
    .use(AddressList);
    app.config.globalProperties.$axios = axios;
    checkPermissionMix(app);
}