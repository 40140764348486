/*
 * @Description: 
 * @Author: you
 * @Date: 2022-04-11
 * @LastEditors: you
 * @LastEditTime:  2022-04-11
 */
/* 路由动态处理 */

export const dynamic= (val)=>{
   if(sessionStorage.getItem("view")){
    let valRouter=JSON.parse(sessionStorage.getItem("view"));
    for (let index = 0; index < valRouter.length; index++) {
    if((valRouter[index].path=='/Home')||(valRouter[index].path=="/Order")){
      valRouter[index].component=() => import(`../views/${valRouter[index].name}/${valRouter[index].name}`); 
      if(valRouter[index].children){
        for (let j = 0; j < valRouter[index].children.length; j++) { 
          valRouter[index].children[j].component=() => import(`../views${valRouter[index].children[j].path}`)
        }
       /*  valRouter[index].children[0].component=() => import(`../views${valRouter[index].children[0].path}`)
        valRouter[index].children[1].component=() => import(`../views${valRouter[index].children[1].path}`) */
      }
    }
   if(valRouter[index].path!='/Home'&& (valRouter[index].path!="/Order")){
      valRouter[index].component=() => import(`../views${valRouter[index].path}`)
    }
  }
  let Login={
      path: '/',
      name: 'Login',
      component:() => import("../views/Login/Login"),
      meta: {
        title: '登录',
        IsLogin:true,
      },
    };
  let OrderPublicMap={
    path: '/Order/OrderPublicMap',
    name: 'OrderPublicMap',
    component:() => import("../views/Order/OrderPublicMap"),
    meta: {
      title: '物流详情',
      IsLogin:false,
      keepAlive: true,
    },
  };
  let QuickRegister={
    path: '/Driver/QuickRegister',
    name: 'QuickRegister',
    component:() => import("../views/Driver/QuickRegister"),
    meta: {
      title: '认证信息',
      IsLogin:false,
      keepAlive: true,
    },
  };
  let CancelDriver={
    path: '/Driver/CancelDriver',
    name: 'CancelDriver',
    component:() => import("../views/Driver/CancelDriver"),
    meta: {
      title: '司机解绑申请',
      IsLogin:false,
      keepAlive: true,
    },
  };
  let error={
    path:'/Error/404',
    name:"404",
    component:() => import("../views/Error/404"),
    meta:{
      title:'404',
      keepAlive:true,
      IsLogin:true,
    },
  };
  valRouter.push(Login);
  valRouter.push(OrderPublicMap);
  valRouter.push(QuickRegister);
  valRouter.push(CancelDriver);
  valRouter.push(error);
  return valRouter; 
   }else{
    for (let index = 0; index < val.length; index++) {
      if((val[index].path=='/Home')||(val[index].path=="/Order" && val[index].name=="Order")||val[index].path=='/'){
        val[index].component=() => import(`../views/${val[index].name}/${val[index].name}`); 
        if(val[index].children){
          val[index].children[0].component=() => import(`../views${val[index].children[0].path}`)
          val[index].children[1].component=() => import(`../views${val[index].children[1].path}`)
        }
      }
      if(val[index].path!='/Home'&& !val[index].children && (val[index].path!="/Order" && val[index].name!="Order")&&val[index].path!='/'){
        val[index].component=() => import(`../views${val[index].path}`)
      }
    }
   return val;
   }
 }
 /* 静态路由 */
/*  export const dynamic= (val)=>{
  for (let index = 0; index < val.length; index++) {
       if((val[index].path=='/')||(val[index].path=="/Order" && val[index].name=="Order")){
         val[index].component=() => import(`../views/${val[index].name}/${val[index].name}`); 
         if(val[index].children){
           val[index].children[0].component=() => import(`../views${val[index].children[0].path}`)
           val[index].children[1].component=() => import(`../views${val[index].children[1].path}`)
         }
       }
       if(val[index].path!='/'&& !val[index].children && (val[index].path!="/Order" && val[index].name!="Order")){
         val[index].component=() => import(`../views${val[index].path}`)
       }
  }
  return val;
 } */