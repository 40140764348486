import { PostApi, GetApi } from "../utils/request";
import ApiVersion from "@/Enum/enumeration"

var CURR_VIEW_VERSION = ApiVersion.ApiVersion.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `/M/${CURR_VIEW_VERSION}`;
export const GetCarType = (params) => {
  //车型查询
  return GetApi({ url: `/api${VIEW_VERSION}/Delivery/DeliveryCarType/GetCarType`, params });
};
export const DeliveryPriceCalcRuleGetPrice = (params) => {
  //运费计算
  return GetApi({
    url: `/api${VIEW_VERSION}/Delivery/DeliveryPriceCalcRule/GetPrice`,
    data: params,
  });
};
