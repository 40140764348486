import { createStore } from 'vuex'
import {GetHomePage } from  '@/serviceApi/Sales'
import {GetCurrUserFullFund} from "@/serviceApi/User";
import { GetCarType} from "@/serviceApi/Delivery";
import banner from "@/static/img/home/banner.jpg";
import carton from "@/static/img/home/carton.jpg";
import { UserInfoGetCurrUserInfo} from "@/serviceApi/User";
import userAvatar from "@/static/img/user/default-head.jpg"
import { GetCurrUserProcessingCount,GetCurrDriverProcessingCount} from "@/serviceApi/Order";
export default createStore({
  state: {
    rotationChart:"",
    data:"",
    modelName:"",
    userInformation:"",
    driverScore:"",
    personalInformation:{
    portraitUrl:userAvatar
    },
    positionInformation:{},
    addressData:{},
  },
  mutations: {
      SET_HEADER_NAV_LIST:(state,newVal)=>{
        state.rotationChart=newVal
      },
      SET_HEADER_NAV_Balance:(state,newVal)=>{
        state.data=newVal
      },
      SET_HEADER_NAV_GetCarTypename:(state,newVal)=>{
        state.modelName=newVal
      },
      SET_HEADER_NAV_GetCurrUserProcessingCount:(state,newVal)=>{
        state.userInformation=newVal
      },
      SET_HEADER_NAV_GetCurrDriverProcessingCount:(state,newVal)=>{
        state.driverScore=newVal
      },
      SET_HEADER_NAV_GetCurrUserInfo:(state,newVal)=>{
        state.personalInformation=newVal;
      }
  },
  actions: {
    async HomePage({commit},params){
      if (!this.state.rotationChart || this.state.rotationChart.length == 0) {
        commit('SET_HEADER_NAV_LIST', [{ bannerImg: banner, bannerLink: "" },{bannerImg: carton, bannerLink: "" }])
      }
      let res = await GetHomePage(params.type)
      if (res.code == 1) {
      commit('SET_HEADER_NAV_LIST', res.data)
        params.callback(res.data)
      }
    },
    async GetCurrUserFullFund({commit},params){
      let res=await GetCurrUserFullFund(params.getCouponList)
      if(res.code==1){
        commit('SET_HEADER_NAV_Balance',res.data)
        params.callback(res.data)
      }
    },
    async GetCarTypename({commit},params){
      let res=await GetCarType()
       if(res.code==1){
        commit('SET_HEADER_NAV_GetCarTypename',res.data)
        params.callback(res.data)
       }
    },
    async UserProcessingCount({commit},params){
      let res=await GetCurrUserProcessingCount()
        if(res.code==1){
          commit('SET_HEADER_NAV_GetCurrUserProcessingCount',res.data)
          params.callback(res.data)
        }
      
    },
    async DriverProcessingCount({commit},params){
      let res=await GetCurrDriverProcessingCount()
     if(res.code==1){
      commit('SET_HEADER_NAV_GetCurrDriverProcessingCount',res.data)
      params.callback(res.data)
     }
    },
    async GetCurrUserInfo({commit},params){
       let res=await UserInfoGetCurrUserInfo();
       if(res.code==1){
         if(!res.data.portraitUrl||res.data.portraitUrl.length==0){
           res.data.portraitUrl=userAvatar;
         }
        commit('SET_HEADER_NAV_GetCurrUserInfo',res.data)
        params.callback(res.data)
       } 
    }
  },
  modules: {
  
  }
})
