/*
 * @Description: 
 * @Author: 优
 * @Date: 2021-06-11 15:54:16
 * @LastEditors: 优
 * @LastEditTime: 2021-07-27 09:11:18
 */
import axios from 'axios'
import { Toast } from 'vant'
import  {GetToken,ErrorHandling}from "./request";
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['Cache-Control'] = 'no-cache'
axios.defaults.headers['Pragma'] = 'no-cache'
const requestAxios = axios.create({})
requestAxios.defaults.timeout = 300000;
const pending = new Map()
/**
 * 添加请求
 * @param {Object} config 
 */
 export  const changePending  = (config) => {
 const url = [
 config.method,
 config.url,
 config.params,
 config.data
 ].join('&')
 config.cancelToken = config.cancelToken || new axios.CancelToken(cancel => {
 if (!pending.has(url)) { // 如果 pending 中不存在当前请求，则添加进去
  pending.set(url, cancel)
 }
 else if(pending.has(url)){
  const cancel = pending.get(url)
  cancel(url)
  pending.delete(url)
 }
 })
}
 /**
  * 清空 pending 中的请求（在路由跳转时调用）
  */
 export const clearPending = () => {
  for (const [url, cancel] of pending) {
    cancel(url)
  }
  pending.clear()
 }
  /**
   * @description 公共请求方法
   * @author 优
   * @param {string} requestWay 请求方式
   * @param {string} url 请求地址
   * @param {json} param  请求参数
   * @returns {Promise} Promise对象
   */
 requestAxios.AxiosRequest=(requestWay,param)=>{
  if (param){
    return new Promise((resolve, reject) =>{
        requestAxios[requestWay](param.url, param.data).then(res =>{
          resolve(res);
        }).catch(err =>{
          reject(err);
        });
    });
  } else {
    return new Promise((resolve, reject) =>{
      requestAxios[requestWay](param.url).then(res =>{
          resolve(res);
        }).catch(err =>{
          reject(err);
        });
    });
  }
 }
// 拦截请求
requestAxios.interceptors.request.use(
  config => {  
   return GetToken(config);
  },
  err => {
    return Promise.reject(err)
  }
)

//请求响应
requestAxios.interceptors.response.use(res => {
  changePending(res) 
  if (res.status != 200) {
    return Promise.reject(res)
  }
  return res.data
},
  error => {
    if(axios.isCancel(error)){
    }
    const { response } = error
    if (response) {
      return ErrorHandling(response,error);
    }
    else {
      if (!window.navigator.onLine) {
        //客户端断网了，将页面跳转到一个断网的页面，可以结合路由使用
      return Toast.fail('网络中断，请重试')    
      }
    }
    return Promise.reject(error)
  })
export default requestAxios