<template>
  <router-view></router-view>
</template>
<script>
import { onMounted, reactive } from "@vue/runtime-core";
import { start } from "@/utils/signalR.js"
import { noBugInCode, locationPositioning } from "../src/utils/location.js"
import ChatReading from "../src/utils/chat.js";
export default {
  setup () {
    const state = reactive({
      setInter: null,
    });
    onMounted(() => {
      start();
      noBugInCode();
      ChatReading();
    });
    if (state.setInter) {
      clearTimeout(state.setInter);
      state.setInter = null;
    }
    state.setInter = setInterval(() => {
      locationPositioning();
    }, process.env.VUE_APP_LOCATION_RECORD_TIME);
    return {
      state
    }
  }
};
</script>
<style lang="less">
@import './static/css/main.css';
@import './static/css/base.css';
</style>
