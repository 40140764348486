/*
 * @Description: 
 * @Author: 优
 * @Date: 2021-04-11 15:54:16
 * @LastEditors: 优
 * @LastEditTime: 2021-07-27 09:11:18
 */
export const setCookie = (name, value, expires, path) => {
  var cookie = name + "=" + encodeURIComponent(value) + ";";
  if (expires) {//时间格式new Date()
    cookie += "expires=" + expires.toUTCString() + ";";
  }
  if (path) {
    cookie += "path=" + path + ";";
  }else{
    cookie += "path=/;";
  }
  document.cookie = cookie;
}

export const setCookietime = (name, value) => {  //设置cookie的有效时间
  let expires = new Date(new Date().setMinutes(new Date().getMinutes()+10));
  return setCookie(name, value, expires, "/");
}

export const delCookie = (name, path) => {//删除cookie
  setCookie(name, '', new Date("1970-1-1"), path);
}

export const getCookie = (name) => {
  var reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  var arr = document.cookie.match(reg);
  if (arr) {
    return decodeURIComponent(arr[2]);
  } else {
    return null;
  }
}