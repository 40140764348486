import { PostApi, GetApi } from "../utils/request";
import ApiVersion from "@/Enum/enumeration"

var CURR_VIEW_VERSION = ApiVersion.ApiVersion.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `/M/${CURR_VIEW_VERSION}`;

export const UserRedPackGetPage = (params) => {
  //红包分页查询
  var data = {
    url: `/api${VIEW_VERSION}/User/UserRedPack/GetPage`,
    data: params,
  };
  return GetApi(data);
};
export const UserRedPackAdd = (params) => {
  //红包领取
  var data = {
    url: `/api${VIEW_VERSION}/User/UserRedPack/Add`,
    data: params,
  };
  return PostApi(data);
};
export const UserCouponGetPage = (params) => {
  //优惠券分页查询
  var data = {
    url: `/api${VIEW_VERSION}/User/UserCoupon/GetPage`,
    data: params,
  };
  return GetApi(data);
};
export const UserCouponAdd = (params) => {
  //优惠券领取
  var data = {
    url: `/api${VIEW_VERSION}/User/UserCoupon/Add`,
    data: params,
  };
  return PostApi(data);
};
export const UserCouponGet = (params) => {
  //优惠券查询
  var data = {
    url: `/api${VIEW_VERSION}/Sales/SalesCoupon/Get`,
    data: { CouponId: params },
  };
  return GetApi(data);
};
export const UserRedPackGet = (params) => {
  //紅包查询
  var data = {
    url: `/api${VIEW_VERSION}/Sales/SalesRedPack/Get`,
    data: { RedPackId: params },
  };
  return GetApi(data);
};

export const GetHomePage = (params) => {
  //获取横幅配置
  var data = {
    url: `/api${VIEW_VERSION}/Sales/SalesBanner/GetBanner`,
    data: { bannerType: params },
  };
  return GetApi(data);
};
