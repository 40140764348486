/*
 * @Description:
 * @Author: 优
 * @Date: 2021-06-11 15:54:16
 * @LastEditors: 优
 * @LastEditTime: 2021-07-27 09:11:18
 */

import { PostApi, PutApi, GetApi, NoTokenGetApi,DeleteApi} from "../utils/request";
import ApiVersion from "@/Enum/enumeration"

var CURR_VIEW_VERSION = ApiVersion.ApiVersion.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `/M/${CURR_VIEW_VERSION}`;
export function OrderInfoAdd(params) {
  //用户下单
  return PostApi({ url: `/api${VIEW_VERSION}/Order/OrderInfo/Add`, data: params });
}
export const OrderInfoGetUserPage = (params) => {
  //用户订单
  return GetApi({ url: `/api${VIEW_VERSION}/Order/OrderInfo/GetUserPage`, data: params });
};
export const GetOrderFreightCal = (params) => {
  //用户计价单查询
  return GetApi({
    url: `/api${VIEW_VERSION}/Order/OrderFreightCalc/Get`,
    data: { orderNo: params,intercept:true}
  });
};
export const GetWaitingOne = (params) => {
  //用户订单详情
  return GetApi({ url: `/api${VIEW_VERSION}/Order/OrderInfo/GetWaitingOrder`, data: params });
};
export const userOrderDetails = (params) => {
  //用户订单详情
  return GetApi({ url: `/api${VIEW_VERSION}/Order/OrderInfo/Get`, data: params, intercept:true, });
};
export const GetPublic = (params) => {
  //用户订单详情（订单公共地图使用）
  return NoTokenGetApi({ url: `/api${VIEW_VERSION}/Order/OrderInfo/GetPublic`, data: params });
};
export const GetCurrUserProcessingCount = (params) => {
  //用户订单信息提醒
  return GetApi({
    url: `/api${VIEW_VERSION}/Order/OrderInfo/GetCurrUserProcessingCount`,
    data: params,
  });
};
export const userCancelOrder = (params) => {
  //用户取消订单
  return PutApi({ url: `/api${VIEW_VERSION}/Order/OrderInfo/UserCancelOrder`, data: params });
};
export const UpdateUserConfirmOrder = (params) => {
  //用户确认订单送达
  return PutApi({
    url: `/api${VIEW_VERSION}/Order/OrderInfo/UserConfirm`,
    data: params,
  });
};
export const UpdateUserConfirmPickUpOrder = (params) => {
  //用户确认取货
  return PutApi({
    url: `/api${VIEW_VERSION}/Order/OrderInfo/UserConfirmPickUp`,
    data: params,
  });
};
export const GetAmapIpLocation = (params) => {
  //逆地理转码
  return NoTokenGetApi({ url: "/amapapiv3/ip", data: { type: 4, ip: params, key: process.env.VUE_APP_KEY_AMAP } });
};
export const OrderEvaluationAddUser = (params) => {
  //用户评价
  return PostApi({ url: `/api${VIEW_VERSION}/Order/OrderEvaluation/AddUser`, data: params });
};
export const OrderEvaluationAddDriver = (params) => {
  //司机评价
  return PostApi({ url: `/api${VIEW_VERSION}/Order/OrderEvaluation/AddDriver`, data: params });
};

export const driverCancelOrder = (params) => {
  //司机取消订单
  return PutApi({
    url: `/api${VIEW_VERSION}/Order/OrderInfo/DriverCancelOrder`,
    data: params,
  });
};
export const DriverDeliveringOrder = (params) => {
  //司机订单送货
  return PutApi({
    url: `/api${VIEW_VERSION}/Order/OrderInfo/DriverDelivering`,
    data: params,
  });
};
export const Delivered = (params) => {
  //司机送达
  return PutApi({
    url: `/api${VIEW_VERSION}/Order/OrderInfo/DriverDelivered`,
    data: params,
  });
};
export const GetDriverLocation = (params) => {
  /* 获取当前司机坐标 */
  return GetApi({
    url: `/api${VIEW_VERSION}/Order/OrderInfo/GetDriverLocation`,
    data: { orderNo: params },
  });
};
export const GetPublicDriverLocation = (params) => {
  /* 获取当前司机坐标(订单公共地图使用) */
  return NoTokenGetApi({
    url:  `/api${VIEW_VERSION}/Order/OrderInfo/GetPublicDriverLocation`,
    data: { orderNo: params },
  });
};
export const driverReceiveOrder = (params) => {
  //司机接单
  return PutApi({
    url: `/api${VIEW_VERSION}/Order/OrderInfo/DriverReceive`,
    data: params,
  });
};

export function OrderInfoGetPageWaitingOrder(params) {
  //司机待接单查询
  return GetApi({
    url: `/api${VIEW_VERSION}/Order/OrderInfo/GetPageWaitingOrder`,
    data: params,
  });
}
export function OrderInfoGetDriverPage(params) {
  //司机订单查询
  return GetApi({ url: `/api${VIEW_VERSION}/Order/OrderInfo/GetDriverPage`, data: params,intercept:true, });
}
export function OrderInfoGetDriver(params) {
  //司机订单详细
  return GetApi({ url: `/api${VIEW_VERSION}/Order/OrderInfo/GetDriver`, data: params,intercept:true, });
}
export const GetCurrDriverProcessingCount = (params) => {
  //司机订单信息提醒
  return GetApi({
    url: `/api${VIEW_VERSION}/Order/OrderInfo/GetCurrDriverProcessingCount`,
    data: params,
    intercept:true,
  });
};

export const DriverArrivePickUpOrder=(params)=>{
   //司机到达取货点
   return PutApi({
    url: `/api${VIEW_VERSION}/Order/OrderInfo/DriverArrivePickUp`,
    data: params,
  });
}
export const GetDriverSettleOrder=(params)=>{
    //获取司机订单结账信息
    return GetApi({
     url: `/api${VIEW_VERSION}/Order/OrderInfo/GetDriverSettleOrder`,
     data: params,
   });
}
export const GetDriverSettleTotalAmount=(params)=>{
  //获取司机结账总金额
  return GetApi({
   url: `/api${VIEW_VERSION}/Order/OrderInfo/GetDriverSettleTotalAmount`,
   data: params,
 });
}
export const DriverCheckOutOrder=(params)=>{
  //司机订单账单结算
  return PutApi({
   url: `/api${VIEW_VERSION}/Order/OrderInfo/DriverCheckOutOrder`,
   data: params,
 });
}
export const UpdateDriverCancelCheckOut=(params)=>{
  //司机删除已结算账单
  return DeleteApi({
   url: `/api${VIEW_VERSION}/Order/OrderInfo/UpdateDriverCancelCheckOut`,
   data: params,
 });
}
export const DriverSettleAmount=(params)=>{
  //司机修改运费
  return PutApi({
   url: `/api${VIEW_VERSION}/Order/OrderInfo/DriverSettleAmount`,
   data: params,
 });
}
export const GetDriverBillName=(params)=>{
  //获取账单名称
  return GetApi({
   url: `/api${VIEW_VERSION}/Order/OrderInfo/GetDriverBillName`,
   data: params,
 });
}
export const DriverBillSigningOrder=(params)=>{
  //司机账单签收
  return PutApi({
   url: `/api${VIEW_VERSION}/Order/OrderInfo/DriverBillSigningOrder`,
   data: params,
 });
}
export const UpdateDriverBillOrder=(params)=>{
  //司机删除账单
  return DeleteApi({
   url: `/api${VIEW_VERSION}/Order/OrderInfo/UpdateDriverBillOrder`,
   data: params,
 });
}