import { routes } from '../utils/routes';
import {routerEnum} from '../Enum/enumeration';
export const checkPermission=(el, binding)=>{
    let permission = binding.value; // 获取到 v-permission的值
    let vive=Object.keys(permission);
     if(routes.length>routerEnum.NumberRouter){
      let isVive= routes.find((t)=>{return t.name==vive[0]}); 
      let childrenVive=null;
      routes.find((t)=>{
        if(t.children){
          t.children.find((d)=>{
           if( d.name==vive[0]){
            childrenVive=true;
           }
          })
        }
      });
      if (permission) {
        let  hasPermission =null; 
          if(isVive|| childrenVive){
              hasPermission=true;
          }else{
              hasPermission=false;
          }
        if (!hasPermission) {
           // 没有权限 移除Dom元素 或者根据项目需求自行处理DOM
          el.parentNode && el.parentNode.removeChild(el);
        }
      }
     }
};
export default {
    mounted(el, binding) {
      checkPermission(el, binding)
    },
    update(el, binding) {
      checkPermission(el, binding)
    }
  }