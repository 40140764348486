import { PostApi, GetApi,NoTokenPostApi } from "../utils/request";
import ApiVersion from "@/Enum/enumeration"

var CURR_VIEW_VERSION = ApiVersion.ApiVersion.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `/M/${CURR_VIEW_VERSION}`;

export function ImageUploadImage(params) {
  //图片上传
  var data = {
    url: `/api${VIEW_VERSION}/Resource/Image/UploadImage`,
    data: params,
    intercept:true,
  };
  return PostApi(data);
}
export function PublicImageUploadImage(params) {
  //图片上传
  var data = {
    url: `/api${VIEW_VERSION}/Resource/Image/OpenUploadImage`,
    data: params,
    intercept:true,
  };
  return NoTokenPostApi(data);
}
export const GetIp = (params) => {
  //获取用户ip
  var data = {
    url: `/api${VIEW_VERSION}/Resource/Common/GetIp`,
    data: params,
  };
  return GetApi(data);
};
export const GetWxJsSdkSignature = (params) => {
  //获取微信定位
  var data = {
    url: `/api${VIEW_VERSION}/Resource/Common/GetWxJsSdkSignature`,
    data: params,
  };
  return GetApi(data);
};
export const AddDriverCoordinates = (params) => {
  //新增司机当前坐标
  var data = {
    url: `/api${VIEW_VERSION}/Record/RecordLocation/Add`,
    data: params,
  };
  return PostApi(data);
};
