import { GetTokeUserInfo } from "@/utils/validate .js";
import {connection} from "@/utils/signalR.js";
import {chatMsgType} from "@/Enum/enumeration.js"
import { compareTimeMin } from "@/commonContent/js/comm.js";
import { Dialog } from 'vant';
import router from '@/router';
export default ()=>{
    connection.on("signalRMsgMethod", function(ChatContent) {
      let ChatMessage= ChatContent.msg;        
       if(ChatContent.action=="sysMsgMethod"){
        sessionStorage.setItem("sysMsg",JSON.stringify(ChatContent.msg)); 
       }
        if(ChatContent.action=="chatMsgMethod"){
          let chatting=sessionStorage.getItem("chat")
        if(chatting){
         let chattingRecords=JSON.parse(sessionStorage.getItem("chat")).roomMsg[ChatMessage.roomId];
         let arrayNumber=0;
         if(chattingRecords!=null){
             chattingRecords.push(ChatMessage);
             for (let index = chattingRecords.length-1; index >=0; index--) {
                 chattingRecords[index].rowId=arrayNumber++;
                  if(chattingRecords[index].msgUserTag=="other"){
                  chattingRecords[index].other=true;
                }
                if(chattingRecords[index].msgUserTag=="self"){
                chattingRecords[index].self=true;
                }
                if(chattingRecords[index].chatMsgType==chatMsgType.Img){
                  chattingRecords[index].IsImg=true;
                }
                
                if(chattingRecords[index].chatMsgType==chatMsgType.Text){
                chattingRecords[index].text=true;
                if(index!=0 && chattingRecords.length-1!=index){
                  let time=compareTimeMin(chattingRecords[index].createDate,chattingRecords[index+1].createDate);
                  if(time<5){
                     chattingRecords[index].createDate="";
                  }
                }
              } 
                 
               }
            let arr=JSON.parse(sessionStorage.getItem("chat"));
            arr.roomMsg[ChatMessage.roomId]=chattingRecords;
             for (let index = 0; index < arr.usersList.conversation.length; index++) {
              if(arr.usersList.conversation[index].roomId==ChatMessage.roomId && ChatMessage.msgUserTag=="other"){
                arr.usersList.conversation[index].isNewMsgTag=true;
              }
            }
            sessionStorage.setItem("chat",JSON.stringify(arr));
          }
       let chatUserList=sessionStorage.getItem("chat");
        if(chatUserList){
            let chatUser=JSON.parse(sessionStorage.getItem("chat"));
             let isNewMsgTag="";
          if(ChatMessage.msgUserTag=="other"){
            isNewMsgTag=true;
          }else{
            isNewMsgTag=false;
          }
        if(!chatUser.usersList.conversation.find((t=>t.roomId==ChatMessage.roomId))){
            chatUser.usersList.conversation.unshift({roomChatTag:ChatMessage.roomChatTag,isNewMsgTag:isNewMsgTag,roomId:ChatMessage.roomId,roomPortrait:ChatMessage.roomPortrait,userRoomName:ChatMessage.userRoomName})
          }else{
           for (let index = 0; index < chatUser.usersList.conversation.length; index++) {
             if(chatUser.usersList.conversation[index].roomId==ChatMessage.roomId){
               chatUser.usersList.conversation[index].isNewMsgTag=isNewMsgTag;
                 chatUser.usersList.conversation.unshift(chatUser.usersList.conversation.splice(index,1)[0]);
             }
           }
          }
        sessionStorage.setItem("chat",JSON.stringify(chatUser));
          }
        }
        else{
          let isNewMsgTag="";
          if(ChatMessage.msgUserTag=="other"){
            isNewMsgTag=true;
          }else{
            isNewMsgTag=false;
          }
         let chatRoom={
         token:GetTokeUserInfo().uid,
         roomMsg:{},
         usersList:{
          conversation:[{roomChatTag:ChatMessage.roomChatTag,isNewMsgTag:isNewMsgTag,roomId:ChatMessage.roomId,roomPortrait:ChatMessage.roomPortrait,userRoomName:ChatMessage.userRoomName}]
         } 
         }
       sessionStorage.setItem("chat",JSON.stringify(chatRoom)); 
        }
        }
        if(ChatContent.action=="sysMsgMethod" && ChatContent.msg.isOutside){
          if(window.location.pathname=="/Order/OrderDetails"){
            Dialog.confirm({
              message:
                '您有一个新订单,请注意查看!',
              })
              .then(() => {
                router.push({path:"/Order/DriverOrderList"})
              })
              .catch(() => {
                // on cancel
              });
          }
        }
         });
}