import { getCookie } from '@/commonContent/js/Cookie'
const signalR = require("@microsoft/signalr");
  export const connection = new signalR.HubConnectionBuilder()
    .withUrl(""+process.env.VUE_APP_API_BASE+"/chat?Bearer="+getCookie("token")+"", {})
    .configureLogging(signalR.LogLevel.Error) 
    .build(); 
export const start=()=>{
  if(getCookie("token")==""){
    return
  }
  connection.start();
}

  

