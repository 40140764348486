import { GetApi } from "../utils/request";
import ApiVersion from "@/Enum/enumeration"

var CURR_VIEW_VERSION = ApiVersion.ApiVersion.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `/M/${CURR_VIEW_VERSION}`;

  export const ViewApiVersion = (params) => {//获取路由
    var data = {
      url: `/api${VIEW_VERSION}/Auth/View/GetList`,
      data: { apiVersion:params},
      intercept:true
    };
    return GetApi(data);
  };