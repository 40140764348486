/*
 * @Description:
 * @Author: 优
 * @Date: 2021-06-11 15:54:16
 * @LastEditors: 优
 * @LastEditTime: 2021-07-27 09:11:18
 */
import { GetApi, NoTokenPostApi, NoTokenGetApi } from "../utils/request";
import ApiVersion from "@/Enum/enumeration"

var CURR_VIEW_VERSION = ApiVersion.ApiVersion.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `/M/${CURR_VIEW_VERSION}`;

//退出登录
export const Logout = (params) => {
  return GetApi({
    url: `/api${VIEW_VERSION}/User/Account/Logout`,
    data: { returnUrl: params },
  });
};

//刷新token
export const RefreshToken = (params) => {
  return NoTokenGetApi({
    url: `/api${VIEW_VERSION}/User/Account/Refresh`,
    data: { token: params },
  });
};

//登录
export const Login = (params) => {
  return NoTokenPostApi({ url: `/api${VIEW_VERSION}/User/Account/Login`, data: params });
};
