export function removeToken(tokenKey) {
    return window.localStorage.removeItem(tokenKey)
}

export const getLocal = (name) => {
    return localStorage.getItem(name)
}

export const setLocal = (name, value) => {
    localStorage.setItem(name, value)
}

export function GetString(str) {
    if (IsNullOrEmpty(str)) {
        return "";
    }
    return str.toString();
}

//删除指定url的参数
export function delUrlParam(url, aParam) {
    aParam.forEach(item => {
        const fromindex = url.indexOf(`${item}=`) //必须加=号，避免参数值中包含item字符串
        if (fromindex !== -1) {
            // 通过url特殊符号，计算出=号后面的的字符数，用于生成replace正则
            const startIndex = url.indexOf('=', fromindex)
            const endIndex = url.indexOf('&', fromindex)
            const hashIndex = url.indexOf('#', fromindex)

            let reg;
            if (endIndex !== -1) { // 后面还有search参数的情况
                const num = endIndex - startIndex
                reg = new RegExp(`${item}=.{${num}}`)
                url = url.replace(reg, '')
            } else if (hashIndex !== -1) { // 有hash参数的情况
                const num = hashIndex - startIndex - 1
                reg = new RegExp(`&?${item}=.{${num}}`)
                url = url.replace(reg, '')
            } else { // search参数在最后或只有一个参数的情况
                reg = new RegExp(`&?${item}=.+`)
                url = url.replace(reg, '')
            }
        }
    });
    const noSearchParam = url.indexOf('=')
    if (noSearchParam === -1) {
        url = url.replace(/\?/, '') // 如果已经没有参数，删除？号
    }
    return url
}
/*
 * 判断字符是否为空或null
 */
export function IsNullOrEmpty(str) {
    if (typeof str == "undefined" || str == null || str === "" || str.toString() === "NaN") {
        return true;
    }
    return false;
}

/*校验邮箱格式 */
export function isEmailCode(str) {
    var reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$");
    return reg.test(str);
}

/*校验邮编*/
export function isValidZipCode(str) {
    if (IsNullOrEmpty(str)) {
        return false;
    }

    var reg = /^[1-9][0-9]{5}$/;
    return reg.test(str);
}

/*校验手机格式 */
export function isValidMobile(str) {
    var reg = /^[1](3|4|5|7|8)\d{9}$|^[1](66|98|99)\d{8}$/;
    return reg.test(str);
}

/*电话有效性（固话和手机）*/
export function isValidPhoneAndMobile(str) {
    if (IsNullOrEmpty(str)) {
        return false;
    }

    var reg = /^((\([0]\d{2,3}\))|[0](\d{2,3}|\d{2,3}-))?\d{7,8}$|^[1](3|4|5|7|8)\d{9}$|^[1](66|98|99)\d{8}$/;
    return reg.test(str);
}

/*身份证校验*/
export function verifyIdCard(str) {
    var r = false;
    if (IsNullOrEmpty(str)) {
        r = false;
    }
    if (str.length == 15) {
        var reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/;
        return reg.test(str);
    }
    if (str.length == 18) {
        var reg = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[A-Z])$/;
        return reg.test(str);
    }
    return r;
}

/*校验固定电话码格式 */
export function isTelCode(str) {
    var reg = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/;
    return reg.test(str);
}

/*校验登录账号格式 6-16字符 数字、字母*/
export function isUserID(str) {
    var patrn = /^([a-zA-Z0-9]){6,16}$/;
    return patrn.exec(str);
}

/*转为正整数*/
export function isValidPositiveInteger(str) {
    var d = GetInt(str);
    if (d < 0) {
        d = -d;
    }
    return d;
}

/*校验密码6-16字符 不包含空格    !!!!!之所以叫这个名字是因为isPassword方法已经存在 */
export function isPasswd(str) {
    var patrn = /^([A-Za-z0-9]|[~!@#$%^&*()_=`\[\]\\{}|;':",.<>?]){6,16}$/;
    return patrn.exec(str);
}

/*密码高级验证  >>>>>  校验密码6-16字符 必须包含大小字母、数字、特称字符 */
export function isAdvancedPassword(str) {
    var patrn = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{6,16}$/;
    return patrn.exec(str);
}

/*密码中级验证  >>>>>  校验密码6-16字符 必须包含字母、数字、特称字符 */
export function isIntermediatePassword(str) {
    var patrn = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{6,16}$/;
    return patrn.exec(str);
}

/*密码初级验证  >>>>>  校验密码6-16字符 必须包含字母、数字 */
export function isPrimaryPassword(str) {
    var patrn = /^(?=.*[0-9])(?=.*[a-zA-Z]).{6,16}$/;
    return patrn.exec(str);
}

//时间戳转datetime   datet=/Date(时间戳)/
export function transferDatetime(datet) {
    //datet不能为null
    if (IsNullOrEmpty(datet)) {
        datet = "";
    }
    var date = new Date(Number(datet.substring(6, datet.length - 2)));
    Y = date.getFullYear() + '-';
    M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    D = p(date.getDate()) + ' ';
    h = p(date.getHours()) + ':';
    m = p(date.getMinutes()) + ':';
    s = p(date.getSeconds());
    return Y + M + D + h + m + s;
}

//获取当前时间 时间戳
export function GetTimestamp() {
    var timestamp = (new Date()).getTime();
    return timestamp;
}

//将时间转换为时间戳
export function DateToTimestamp(timestampVal) {
    var timestamp = new Date(transferDatetime(timestampVal)).getTime();
    return timestamp;
}

/*
 * 时间格式化
 * yyyy-MM-dd
 * yyyy-MM-dd HH:mm:ss
 */
export function formatDate(date, format) {
    if (!date) return "";
    if (!format) format = "yyyy-MM-dd";
    date = new Date(date);
    var dict = {
        "yyyy": date.getFullYear(),
        "yy": date.getFullYear().toString().substr(2),
        "M": date.getMonth() + 1,
        "d": date.getDate(),
        "H": date.getHours(),
        "m": date.getMinutes(),
        "s": date.getSeconds(),
        "MM": ("" + (date.getMonth() + 101)).substr(1),
        "dd": ("" + (date.getDate() + 100)).substr(1),
        "HH": ("" + (date.getHours() + 100)).substr(1),
        "mm": ("" + (date.getMinutes() + 100)).substr(1),
        "ss": ("" + (date.getSeconds() + 100)).substr(1)
    };
    return format.replace(/(yyyy|yy|MM?|dd?|HH?|ss?|mm?)/g, function () {
        return dict[arguments[0]];
    });
}

/*
 * 字符串部分隐藏
 * @param text:字符串
 * @param front:前面显示的字数
 * @param back:末尾显示的字数
 * @param hide:超出显示长度,字符串显示false或隐藏true
 */
export function ShowShortString(text, front, back, hide) {
    var newText = "";
    if (IsNullOrEmpty(text)) {
        return newText;
    }
    front = front < 0 ? 0 : front;
    back = back < 0 ? 0 : back;
    if (!hide && text.length < front + back) {
        front = text.length;
        back = 0;
    }
    if (hide && text.length < front + back) {
        front = 0;
        back = 0;
    }
    newText += text.substring(0, front);
    for (var i = 0; i < text.length - back - front; i++) {
        newText += '*';
    }
    newText += text.substring(text.length - back, text.length);
    return newText;
}


/*
* url 目标url
* name 需要替换的参数名称
* value 替换后的参数的值
* return url 参数替换后的url
*/
export function editUrlQuery(url, name, value) {
    var pattern = name + '=([^&#$]*)';
    var replaceText = name + '=' + value;
    if (url.match(pattern)) {
        var tmp = '/(' + name + '=)([^&#$]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
    } else {
        var point = url.match('#.*') ? url.match('#.*') : "";
        var turl = url.replace(point, "");
        if (url.match('[\?]')) {
            return turl + '&' + replaceText + point;
        } else {
            return turl + '?' + replaceText + point;
        }
    }
}

/*
 * 获取URL参数
 */
export function getSysMsgQueryString(url, name) {
    var nowUrl = url.slice(1);
    var qArray = nowUrl.split("&");
    for (var i = 0; i < qArray.length; i++) {
        var vArray = qArray[i].split("=");
        if (vArray.length > 0) {
            if (vArray[0] === name && vArray.length > 2) {

                return decodeURIComponent(vArray[1] + '=' + vArray[2]);
            } else {
                return decodeURIComponent(vArray[1]);

            }
        }

    }
    return "";
}

/*
 * 修改当前url参数
 */
export function editLocUrlQuery(name, value) {
    var url = location.href.toString();
    return editUrlQuery(url, name, value);
}


/*
获取url参数值
*/
export function getQueryString(url, name) {
    var nowUrl = url.slice(1);
    var qArray = nowUrl.split("&");
    for (var i = 0; i < qArray.length; i++) {
        var vArray = qArray[i].split("=");
        if (vArray[0].replace(/\s+/g, "") === name) {
            return decodeURIComponent(vArray[1]);
        }
    }
    return "";
}
export function returnFloat(value){  //保留小数
    var value=Math.round(parseFloat(value)*100)/100;
    var xsd=value.toString().split(".");
    if(xsd.length==1){
    value=value.toString()+".00";
    return value;
    }
    if(xsd.length>1){
    if(xsd[1].length<2){
    value=value.toString()+"0";
    }
    return value;
    }
 }
 export function returnFloates(value){  //保留小数
    var value=Math.round(parseFloat(value)*10)/10;
    var xsd=value.toString().split(".");
    if(xsd.length==1){
    value=value.toString()+".0";
    return value;
    }
    if(xsd.length>1){
    if(xsd[1].length<1){
    value=value.toString()+"0";
    }
    return value;
    }
 }
/*
获取url参数值(稳定)
*/
 export function getUrlParam(name) {
    var nowUrl = document.location.search.slice(1);
    if (nowUrl === "") {
        return "";
    }
    var qArray = nowUrl.split("&");
    for (var i = 0; i < qArray.length; i++) {
        var vArray = qArray[i].split("=");
        if (vArray[0] === name) {
            return decodeURIComponent(vArray[1]);
        }
    }
    return "";
}

//cookie操作
export function setCookie(c_name,value,expire) {
    var date=new Date()
    date.setSeconds(date.getSeconds()+expire)
    document.cookie=c_name+ "="+escape(value)+"; expires="+date.toGMTString()
}
 
export function getCookie(c_name){
    if (document.cookie.length>0){
        let c_start=document.cookie.indexOf(c_name + "=")
        if (c_start!=-1){ 
            c_start=c_start + c_name.length+1 
            let c_end=document.cookie.indexOf(";",c_start)
            if (c_end==-1) c_end=document.cookie.length
                return unescape(document.cookie.substring(c_start,c_end))
            } 
        }
    return ""
}
 
export function delCookie(name){
    setCookie(name, "", -1)
}



export function timeStamp(StatusMinute){	
	var day=parseInt(StatusMinute/60/24);
        var hour=parseInt(StatusMinute/60%24);
	var min= parseInt(StatusMinute % 60);
	StatusMinute="";
	if (day > 0)
	{
	    StatusMinute= day + "天";
	} 
	if (hour>0)
	{
	    StatusMinute += hour + "小时";
	} 
	if (min!=0)
	{
	    StatusMinute += parseFloat(min) + "分钟";
	}
	    return StatusMinute;
	}
    
    // 最简单数组去重法
/*
* 新建一新数组，遍历传入数组，值不在新数组就push进该新数组中
* IE8以下不支持数组的indexOf方法
* */
export function uniq(array){
    var temp = []; //一个新的临时数组
    for(var i = 0; i < array.length; i++){
        if(temp.indexOf(array[i]) == -1){
            temp.push(array[i]);
        }
    }
    return temp;
}

 
export function GetBool(str) {
    if (IsNullOrEmpty(str)) {
        return false;
    }
    return str.toString().toLowerCase() === "true";
}
/* 路径储存删除 */
export const BackOff=(val)=>{
/*     let route=JSON.parse(sessionStorage.getItem("route"));
     for (let index = 0; index < route.length; index++) {
      if(route[index]==val.path){
         route.splice(index,1);
      } 
    }
    sessionStorage.setItem("route",JSON.stringify(route));  */
}
/* 时间日期转换 */
  export const DateTime=(val)=>{
    if(!val){
        val = new Date();
    } 
    Date.prototype.Format = function (fmt) {
        var o = {
            "M+": this.getMonth() + 1, //月份 
            "d+": this.getDate(), //日 
            "H+": this.getHours(), //小时 
            "m+": this.getMinutes(), //分 
            "s+": this.getSeconds(), //秒 
            "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
            "S": this.getMilliseconds() //毫秒 
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    };
   return val.Format("yyyy-MM-dd HH:mm:ss")
  }
/* 判断时间大小 */
export const compareTimeMin = (time1,time2) => {
    let m = 0;
    //判断开始时间是否大于结束日期
    if(time1>time2)
    {
        m = 0;
    }else{
        //截取字符串，得到日期部分"2009-12-02",用split把字符串分隔成数组
        var begin1 = time1.substr(0,10).split("-");
        var end1 = time2.substr(0,10).split("-");
        
         var beginTime1 = time1.substr(11,8).split(":");
         var endTime1 = time2.substr(11,8).split(":");
        //将拆分的数组重新组合，并实例成化新的日期对象
        var date1 = new Date(begin1[0] + '-' + begin1[1] + '-' + begin1[2]+ ' '+ beginTime1[0]+':' +beginTime1[1]+':'+beginTime1[2]);
        var date2 = new Date(end1[0] + '-' + end1[1] + '-' + end1[2]+ ' '+ endTime1[0]+':' +endTime1[1]+':'+endTime1[2]);    
        
        //得到两个日期之间的差值m，以分钟为单位
        m=parseInt(Math.abs(date2-date1)/1000/60); 
    }
    return m;
}
/*参数拼接 */
export const SpreadingParameter=(val)=>{
  var url="";    
  Object.keys(val).forEach(function(key){
   if(url==""){
       url +="?";
   }
   url+=key+"="+val[key]+"&"
})
url=url.length > 0 ? url.substring(0, url.length - 1) : "";
return url;
}
//身份证验证
export const IsCard=(num)=> {
        //if (isNaN(num)) {alert("输入的不是数字！"); return false;} 
        var len = num.length, re;
        if (len == 15)
            re = new RegExp(/^(\d{6})()?(\d{2})(\d{2})(\d{2})(\d{2})(\w)$/);
        else if (len == 18)
            re = new RegExp(/^(\d{6})()?(\d{4})(\d{2})(\d{2})(\d{3})(\w)$/);
        else {
            //alert("输入的数字位数不对。"); 
            return false;
        }
        var a = num.match(re);
        if (a != null) {
            if (len == 15) {
                var D = new Date("19" + a[3] + "/" + a[4] + "/" + a[5]);
                var B = D.getYear() == a[3] && (D.getMonth() + 1) == a[4] && D.getDate() == a[5];
            }
            else {
                var D = new Date(a[3] + "/" + a[4] + "/" + a[5]);
                var B = D.getFullYear() == a[3] && (D.getMonth() + 1) == a[4] && D.getDate() == a[5];
            }
            if (!B) {
                //alert("输入的身份证号 "+ a[0] +" 里出生日期不对。"); 
                return false;
            }
        }
        if (!re.test(num)) {
            //alert("身份证最后一位只能是数字和字母。");
            return false;
        }
        return true;
    
}