 export const OrderType={
         /// <summary>
        /// 用户发单
        /// </summary>
    User : 1,
        /// <summary>
        /// 商城发单
        /// </summary>
    Store : 2,
        /// <summary>
        /// 商城发单(指定司机)
        /// </summary>
     StoreDriver : 3
}
export const OrderSource=
    {
        /// <summary>
        /// 物流
        /// </summary>
        Delivery: 1,
        /// <summary>
        /// 商城
        /// </summary>
        Mall : 2,
        /// <summary>
        /// xerp
        /// </summary>
        Xerp :3,
        /// <summary>
        /// 其他
        /// </summary>
        Other: 99
    }
export const  OrderStatusEnum={
      /// <summary>
        /// 待支付
        /// </summary>
        Unpaid: 1,
        /// <summary>
        /// 待接单
        /// </summary>
        Waiting : 2,
        /// <summary>
        /// 已接单
        /// </summary>
        Received : 3,
        /// <summary>
        /// 到达取货
        /// </summary>
        ArrivePickUp: 11,
        /// <summary>
        /// 确认取货
        /// </summary>
        ConfirmPickUp : 12,
        /// <summary>
        /// 送货中
        /// </summary>
        Delivering : 21,
        /// <summary>
        /// 已送达
        /// </summary>
        Delivered : 22,
        /// <summary>
        /// 已完成
        /// </summary>
        Completed:99,
        /// <summary>
        /// 已取消
        /// </summary>
        Cancelled : 89,
        /// <summary>
        /// 原路退回
        /// </summary>
        ReturnBack : 88
}

export const TransferStatus={
    /// <summary>
        /// 待转账
        /// </summary>

        Unpaid : 1,
        /// <summary>
        /// 已转账(成功)
        /// </summary>

        Paid : 2,
        /// <summary>
        /// 已关闭
        /// </summary>
        Closed : 3,
        /// <summary>
        /// 转账失败
        /// </summary>
        Failed : 4,
        /// <summary>
        /// 转账中(除明确状态外的其他状态)
        /// </summary>
        Paying : 9

}
export const chatMsgType={
    /// <summary>
     /// 文本
     /// </summary>
     Text :1,
     /// <summary>
     /// 图片
     /// </summary>
     Img :2,
     /// <summary>
     /// 语音
     /// </summary>
     Voice: 3,
     /// <summary>
     /// 视频
     /// </summary>
     Video: 4
}
export const routerEnum={
     /// <summary>
     /// 条数
     /// </summary>
     NumberRouter:5,
}
export const RoomStatus={
    
        /// <summary>
        /// 正常
        /// </summary>

        Normal : 1,

        /// <summary>
        /// 关闭
        /// </summary>
        Close :9
}
export const ApiVersion={
    apiVersion_str:{
        Open_V1: "Open_V1",
        M_V1: "M_V1",
        S_V1: "S_V1",
        V0: "V0"
    },
    API_VIEW_VERSION: {
        CURR_API_VIEW_VERSION: 'V1',   //当前系统接口版本号， 例如： V0,V1,V2,V3
    },
}
export const RouterStatus={
  ///有效
  Normal:1,
  ///无效
  Invalid:2
};
export default{
    ApiVersion
}
     /// <summary>
    /// 订单结账
    /// </summary>
export const CheckOutStatus={
        /// <summary>
        /// 未结账
        /// </summary>
        Unclosed : 1,
        /// <summary>
        /// 已结账
        /// </summary>
        Closed :2,
         /// <summary>
        /// 已签收
        /// </summary>
        SignedIn: 3,
    }
